<template>
  <v-dialog
    v-model="isVisible"
    max-width="600"
    :persistent="isSending"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="isDisplayed"
        v-bind="attrs"
        v-on="on"
        color="warning"
        :disabled="isDisabled"
      >
        <v-icon left>mdi-email</v-icon>
        <span>Envoyer</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="warning white--text">Envoyer la décision</v-card-title>

      <v-card-text class="pt-5">
        <div>
          Êtes-vous sûr de vouloir
          <span class="font-weight-bold">envoyer la décision à l'étudiant ?</span>
        </div>
        <div class="mt-2">
          Celui-ci la recevra par email sous forme de fichier PDF et vous serez ajouté en copie.
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          @click="isVisible = false"
          text
          :disabled="isSending"
        >
          Annuler
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          @click="sendAnalysis"
          text
          color="warning"
          :loading="isSending"
        >
          Envoyer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'DialogConfirmAnalyseSending',

  props: {
    isDisplayed: Boolean,
    isDisabled: Boolean,
    registrationId: Number,
    type: String,
  },

  data: () => ({
    isVisible: false,
    isSending: false,
  }),

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    async sendAnalysis() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.isSending = true;
        const response = await this.$http.post('admin/misc-send-analysis.php', {
          registration_id: this.registrationId,
          type: this.type,
        });
        this.$emit('analyse-sent');
        this.showNotification({
          isVisible: true,
          text: response.data,
          color: 'success',
        });
        this.isVisible = false;
      } catch (error) {
        this.showNotification({
          isVisible: true,
          text: 'L\'envoi de la décision a échoué',
          color: 'error',
        });
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
